// Polyfill for IE11
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import * as config from './config';
import App from './App';
import reducers from './reducers';
import * as asyncActions from './asyncActions';

import 'react-select/dist/react-select.min.css'

import './index.css'


let store = createStore(
	reducers,
	// {},  // preloaded state
	applyMiddleware(
		thunkMiddleware,
		createLogger({ diff: config.DEBUG_LOG_DIFFS }),
	)
);

ReactDOM.render(
	<Provider store={store}><App /></Provider>,
	document.getElementById('root')
);

let urlParams;
(window.onpopstate = () => {
	let match,
		pl     = /\+/g,  // Regex for replacing addition symbol with a space
		search = /([^&=]+)=?([^&]*)/g,
		decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
		query  = window.location.search.substring(1);

	urlParams = {};
	while (true) {
		match = search.exec(query);
		if (!match) break;
		urlParams[decode(match[1])] = decode(match[2]);
	}
})();

store.dispatch(asyncActions.startSurvey({
	slug: urlParams.slug,
	guid: urlParams.guid,
	forceRepass: urlParams.forceRepass,
	ephemeral: urlParams.ephemeral,
}));
// http://localhost:81/poll/?forceRepass=true&guid=1234&slug=testall&ephemeral=true
// http://localhost:81/api/pass/start/testall?ephemeral=true&forceRepass=true&guid=1234
