import React from 'react';


const DebugView = (p) => {
	if (!p.enabled) return null;
	return (
		<div>
			<pre>
_loading:            {JSON.stringify(p.state._loading)}{'\n'}
_survey_hidden:      {JSON.stringify(p.state._survey_hidden)}{'\n'}
_answers:            {JSON.stringify(p.state._answers, null, '  ')}{'\n'}
_highlight_invalid:  {JSON.stringify(p.state._highlight_invalid)}{'\n'}
{'\n'}
_child_questions:    {JSON.stringify(p.state._child_questions)}{'\n'}
_visible_children:   {JSON.stringify(p.state._visible_children)}{'\n'}
_current_flags:      {JSON.stringify(p.state._current_flags)}{'\n'}
_visible_questions:  {JSON.stringify(p.state._visible_questions)}{'\n'}
_invalid_questions:  {JSON.stringify(p.state._invalid_questions)}{'\n'}
			</pre>
		</div>
	);
}

export default DebugView;
