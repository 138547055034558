import { validateISODate } from "./date";
import { validateEmail } from "./email";
import NumericCondition from "./NumericCondition";

export const TYPE_TEXT = 1;
export const TYPE_CHOICE = 2;
export const TYPE_MATRIX = 3;
export const TYPE_SCORE = 4;
export const TYPE_RATING = 5;

export const MATRIX_STYLE_COMPACT = 1;
export const MATRIX_STYLE_VERBOSE = 2;

export const TEXT_STYLE_TEXT = 1;
export const TEXT_STYLE_DATE = 2;
export const TEXT_STYLE_EMAIL = 3;

export const SCORE_STYLE_NPS = 1;
export const SCORE_STYLE_SLIDER = 2;


// Returns a copy of the given array. Element will be added if it's not already
// present.
export const ensureAdded = (element, array) => {
	let ret = array.slice();
	if (ret.indexOf(element) < 0) ret.push(element);
	return ret;
}

// Returns a copy of the given array. Element will be removed if it's present.
export const ensureRemoved = (element, array) => {
	return array.filter((x) => x !== element);
}

// Returns a list of IDs of child questions whose visibility
// can be changed by that question.
export const possibleChildren = (q) => {
	if (q.type !== TYPE_CHOICE) return [];
	let ret = [];
	for (let choice of q.spec.choices) {
		if (!!choice.child_question) {
			ret.push(choice.child_question);
		}
	}
	return ret;
}

// Returns a list of IDs of child questions which are visible with the given
// answers.
export const visibleChildren = (q, answer) => {
	if (q.type !== TYPE_CHOICE) return [];
	if (!answer) return [];
	let ret = [];
	for (let choice of q.spec.choices) {
		if (!!choice.child_question) {
			if (answer.indexOf(choice.id) >= 0) {
				ret.push(choice.child_question);
			}
		}
	}
	return ret;
}


// Flag routines

const applyFlagDeltas = (currentFlags, deltas) => {
	let ret = currentFlags.slice();
	for (let f of deltas) {
		if (f.should_set) {
			ret = ensureAdded(f.name, ret);
		} else {
			ret = ensureRemoved(f.name, ret);
		}
	}
	return ret;
}

const adjustFlagsText = (q, a = '', currentFlags) => {
	return currentFlags.slice();
}

const adjustFlagsChoice = (q, a = [], currentFlags) => {
	let ret = currentFlags.slice();
	for (let option of q.spec.choices) {
		if (a.indexOf(option.id) >= 0) {
			ret = applyFlagDeltas(ret, option.flags_on_selected);
		} else {
			ret = applyFlagDeltas(ret, option.flags_not_selected);
		}
	}
	return ret;
}

/**
 * Makes a map of the set and revoked flags of the matrix cells.
 *
 * @param {object} q - Question object.
 *
 * @returns {Map<number, {"sets": Map<number, string[]>, "revokes": Map<number, string[]>}>} - Map of flags of rows and columns.
 * */
const getMatrixFlagsMap = (q) => {
	let ret = new Map();

	for (const row of q.spec.rows) {
		let rowFlagDetails = {"sets": new Map(), "revokes": new Map()};

		Object.entries(row.flags_sets).forEach(value => {
			rowFlagDetails.sets.set(+value[0], value[1]);
		});
		Object.entries(row.flags_revokes).forEach(value => {
			rowFlagDetails.revokes.set(+value[0], value[1]);
		});

		ret.set(row.id, rowFlagDetails);
	}

	return ret;
}

/**
 * Adjusts flags in matrix questions.
 *
 * @param {object} q - Question object.
 * @param {{"row": number, "answers": number[]}[]} a - Entry answers.
 * @param {string[]} currentFlags - Flags at the current stage of the survey.
 *
 * @returns {string[]} - New array of survey flags.
 * */
const adjustFlagsMatrix = (q, a = [], currentFlags) => {
	let ret = currentFlags.slice();
	const flagsMap = getMatrixFlagsMap(q);

	for (const answerRow of a) {
		for (const colID of answerRow.answers) {
			let row = flagsMap.get(answerRow.row);

			if (row !== undefined) {
				let flags = row.sets.get(colID);

				if (flags !== undefined) {
					for (const flag of flags) {
						ret = ensureAdded(flag, ret);
					}
				}

				flags = row.revokes.get(colID);

				if (flags !== undefined) {
					for (const flag of flags) {
						ret = ensureRemoved(flag, ret);
					}
				}
			}
		}
	}

	return ret;
}


const adjustFlagsScore = (q, a = 0, currentFlags) => {
	let result = currentFlags.slice();

	if (a === 0) return result;		// no answer

	for (const flag of q.spec.flags_to_set) {
		if (NumericCondition.compare(flag.condition, flag.value, a)) {
			result = ensureAdded(flag.label, result);
		}
	}

	for (const flag of q.spec.flags_to_revoke) {
		if (NumericCondition.compare(flag.condition, flag.value, a)) {
			result = ensureRemoved(flag.label, result);
		}
	}

	return result;
}


const adjustFlagsRating = (q, a = [], currentFlags) => {
	let result = currentFlags.slice();
	let answerMap = new Map(a.map(e => [e.row, e.answer]));

	for (const row of q.spec.rows) {
		const answer = answerMap.get(row.id) ?? 0

		if (answer === 0) continue;		// no answer

		for (const flag of row.flags_sets) {
			if (NumericCondition.compare(flag.condition, flag.value, answer)) {
				result = ensureAdded(flag.label, result);
			}
		}

		for (const flag of row.flags_revokes) {
			if (NumericCondition.compare(flag.condition, flag.value, answerMap.get(row.id) ?? 0)) {
				result = ensureRemoved(flag.label, result);
			}
		}
	}

	return result;
}

// Takes current list of flags, applies flags according to question rules,
// returns new list of flags. Guaranteed to return a new array.
export const adjustFlags = (q, a, currentFlags = []) => {
	switch(q.type) {
		case TYPE_TEXT:
			return adjustFlagsText(q, a, currentFlags);
		case TYPE_CHOICE:
			return adjustFlagsChoice(q, a, currentFlags);
		case TYPE_MATRIX:
			return adjustFlagsMatrix(q, a, currentFlags);
		case TYPE_SCORE:
			return adjustFlagsScore(q, a, currentFlags);
		case TYPE_RATING:
			return adjustFlagsRating(q, a, currentFlags);
		default:
			console.error('Unknown question type: '+q.type);
			return true;
	}
};


export const flagsSatisfy = (current, required) => {
	for (let flag of required) {
		if (current.indexOf(flag) < 0) return false;
	}
	return true;
}

// Returns true if the question will be visible in the given conditions.
export const isVisible = (q, flags = [], childrenList = [], visibleChildren = []) => {
	// Children flags are ignored
	if (childrenList.indexOf(q.id) >= 0) {
		return (visibleChildren.indexOf(q.id) >= 0);
	}
	// For top-level questions, check against flags
	for (let flag of q.flags_needed) {
		if (flags.indexOf(flag) < 0) return false;
	}
	return true;
}


// Validation routines

const textIsValid = (q, a = '') => {
	const style = q.spec?.style;

	if (style === TEXT_STYLE_TEXT || style === undefined) {
		if ((q.spec.max > 0) && (a.length > q.spec.max)) return false;
		if (a.length < q.spec.min) return false;
	} else if (style === TEXT_STYLE_DATE) {
		if (!q.spec?.is_required && !a) return true;
		return validateISODate(a);
	} else if (style === TEXT_STYLE_EMAIL) {
		if (!q.spec?.is_required && !a) return true;
		return validateEmail(a);
	} else {
		throw new Error(`Unexpected text question style: ${style}`);
	}

	return true;
}

const choiceIsValid = (q, a = []) => {
	if ((q.spec.max > 0) && (a.length > q.spec.max)) return false;
	if (a.length < q.spec.min) return false;
	return true;
}

const matrixIsValid = (q, a = []) => {
	// Patch possible server nulls
	// NOTE: This could actually mutate the variable without Redux noticing,
	// so it's a bad practice to do so. It's fine only because `null` and `[]`
	// are equivalent in app logic.
	for (let row of a) {
		if (row.answers == null) row.answers = [];
	}
	// check for max
	if (q.spec.max > 0) {
		for (let row of a) {
			if (row.answers.length > q.spec.max) return false;
		}
	}
	// check for min
	if (q.spec.min > 0) {
		for (let specRow of q.spec.rows) {
			let rows = a.filter((row) => row.row === specRow.id);
			if (rows.length === 0) {
				return false;
			}
			let row = rows[0];
			if (row.answers.length < q.spec.min) {
				return false;
			}
		}
	}
	return true;
}


const scoreIsValid = (q, a = 0) => {
	if (!q.spec?.is_required && a === 0) return true;
	return a >= q.spec.min && a <= q.spec.max;
}


const ratingIsValid = (q, a = []) => {
	const answersMap = new Map(a.map(e => [e.row, e.answer]));

	for (let row of q.spec.rows) {
		const answer = answersMap.get(row.id) ?? 0;

		if (row.is_required && answer === 0) {
			return false;
		} else if (answer < 0 || answer > row.max) {
			return false;
		}
	}

	return true;
}

export const answerIsValid = (q, a) => {
	switch(q.type) {
		case TYPE_TEXT:
			return textIsValid(q, a);
		case TYPE_CHOICE:
			return choiceIsValid(q, a);
		case TYPE_MATRIX:
			return matrixIsValid(q, a);
		case TYPE_SCORE:
			return scoreIsValid(q, a);
		case TYPE_RATING:
			return ratingIsValid(q, a);
		default:
			console.error('Unknown question type: '+q.type);
			return true;
	}
}


// Replaces any {{variables}} in the given string with their values from obj.
// If a variable is not present in obj, expression is left as is.
export const subst = (str, obj) => {
	return str.replace(/{{\s*(.+?)\s*}}/g, (match, p1, p2, p3, offset, string) => {
		if (!!obj[p1]) {
			return obj[p1];
		} else {
			return match;
		}
	});
}
