import { connect } from 'react-redux';
//import * as actions from './actions';
import * as asyncActions from './asyncActions';
import NavigationView from './NavigationView';

const mapStateToProps = (state, ownProps) => {
	let ret = {
		canRewind: false,
		canForward: false,
	}

	if (state._loading) return ret;
	if (!state._survey_hidden) return ret;

	if (!!state.page_data) {
		if (!state.page_data.finished) {
			ret.canForward = true;
			ret.canRewind = state.page_data.can_rewind;
		}
		return ret;
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onForward: () => {
			dispatch(asyncActions.tryForward());
		},
		onRewind: () => {
			dispatch(asyncActions.goRewind());
		},
	};
}

const NavigationContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(NavigationView)

export default NavigationContainer
