import { connect } from 'react-redux'
import SpinnerView from './SpinnerView'

const mapStateToProps = (state) => {
	return {
		loading: state._loading,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
	};
}

const SpinnerContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(SpinnerView)

export default SpinnerContainer
