import React from 'react';

import * as core from './core';


const handleChange = (callback) => {
	return (event) => {
		callback(event.target.value);
	}
}

const QuestionTextView = (p) => {
	let answer = p.answer || [];
	// p.question

	let body = null;
	const questionStyle = p.question.spec.style;

	if (questionStyle === undefined || questionStyle === core.TEXT_STYLE_TEXT) {
		if (p.question.spec.is_multiline) {
			body = (
				<textarea className="form-control" type='text' value={answer} onChange={handleChange(p.onChange)} />
			);
		} else {
			body = (
				<input className="form-control" type='text' value={answer} onChange={handleChange(p.onChange)} />
			);
		}
	} else if (questionStyle === core.TEXT_STYLE_DATE) {
		body = (
			<input className="form-control" type="date" value={answer} onChange={handleChange(p.onChange)} />
		);
	} else if (questionStyle === core.TEXT_STYLE_EMAIL) {
		body = (
			<input className="form-control" type="email" value={answer} onChange={handleChange(p.onChange)} placeholder="example@email.ru" />
		);
	} else {
		throw new Error(`Unexpected question style: ${questionStyle}`);
	}

	return (
		<div className="QuestionTextView">
			{body}
		</div>
	)
}

export default QuestionTextView;
