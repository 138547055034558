import { connect } from 'react-redux';
//import * as actions from './actions';
import PageView from './PageView';

const mapStateToProps = (state, ownProps) => {
	let page = null;
	let questions = null;
	let finished = false;
	let attributes = {};
	if (!!state.page_data) {
		page = state.page_data.data.page;
		questions = state.page_data.data.questions;
		finished = state.page_data.finished;
		attributes = state.attributes;
	}

	let validationFailedMessage = 'Вы что-то не так заполнили.';
	if (!!state.survey && !!state.survey.text_validation_failed) {
		validationFailedMessage = state.survey.text_validation_failed;
	}

	return {
		hidden: (!state._survey_hidden) || finished,
		page,
		questions,
		attributes,
		childrenList: state._child_questions,
		validationFailed: ((state._highlight_invalid) && (state._invalid_questions.length > 0)),
		validationFailedMessage,
	};
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	};
}

const PageContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(PageView)

export default PageContainer
