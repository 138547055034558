import { connect } from 'react-redux';
import * as actions from './actions';
import QuestionView from './QuestionView';

const mapStateToProps = (state, ownProps) => {
	let attributes = {};
	let question = null;
	if (!!state.page_data) {
		attributes = state.attributes;
		for (let q of state.page_data.data.questions) {
			if (q.id === ownProps.qid) {
				question = q;
			}
		}
	}

	let answer = null;
	if (!!question) {
		answer = state._answers[ownProps.qid];
	}

	let valid = true;
	if (state._highlight_invalid) {
		if (state._invalid_questions.indexOf(ownProps.qid) >= 0) {
			valid = false;
		}
	}

	let visible = (state._visible_questions.indexOf(ownProps.qid) >= 0);
	let flags = (state._current_flags);

	return {
		question: question,
		attributes: attributes,
		answer: answer,
		valid: valid,
		visible: visible,
		currentFlags: flags,
	};
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onChange: (newAnswer) => {
		 	dispatch(actions.answerChanged(ownProps.qid, newAnswer));
		},
	};
}

const QuestionContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(QuestionView)

export default QuestionContainer
