export const startSurvey = () => {
	return {
		type: 'SURVEY_START',
	};
}

export const answerChanged = (id, newAnswer) => {
	return {
		type: 'ANSWER_CHANGED',
		id,
		newAnswer,
	};
}

export const loadingStarted = () => {
	return {
		type: 'LOADING_STARTED',
	};
}

export const loadingFinished = (newSurvey, newAttributes, newPageData) => {
	return {
		type: 'LOADING_FINISHED',
		newSurvey,
		newAttributes,
		newPageData,
	};
}

export const loadingFailed = (err) => {
	return {
		type: 'LOADING_FAILED',
		error: err,
	};
}

export const submitFailed = () => {
	return {
		type: 'SUBMIT_FAILED',
	};
}
