import React from 'react';
import QuestionContainer from './QuestionContainer';

import * as core from './core';

import "./PageView.css";


const PageView = (p) => {
	if (!p.page) return null;
	if (p.hidden) return null;

	let questions = p.questions || [];

	let qs = questions.map((q) => {
		if (p.childrenList.indexOf(q.id) >= 0) return null;

		return (
			<QuestionContainer key={q.id} qid={q.id} />
		);
	});

	let val = null;
	if (p.validationFailed) {
		val = (
			<div className="text-danger">
				<i className="fa fa-warning"></i>
				{' '}
				{p.validationFailedMessage}
			</div>
		);
	}

	return (
		<div className="PageView">
			<h2>{core.subst(p.page.title, p.attributes)}</h2>
			<hr />
			{qs}
			{val}
			<p>&nbsp;</p>
		</div>
	)
}

export default PageView;
