import React from 'react';
import * as core from './core';

import "./QuestionMatrixView.css";

// HEADS UP!
// The prop is called `answer`, but inside it there's a key called `answers`.
// Don't you fucking confuse them.


const checked = (answer, rowID, colID) => {
	for (let x of answer) {
		if (x.row === rowID) {
			return (x.answers.indexOf(colID) >= 0);
		}
	}
	return false;
}

// TODO: bind such shit to `this`
const handleChange = (answer, rowID, colID, isRadio, callback) => {
	return () => {
		// create a shallow copy of the answer
		answer = answer.slice()

		// Matrix answers are stored as array of objects like
		// { row: rowID, answers: [ colID, ... ] }
		// First, we check if any of existing entries match the given row ID.
		let matchingEntries = answer.filter((entry) => entry.row === rowID)
		if (matchingEntries.length === 0) {
			// If none match, just create a new entry
			answer.push({
				row: rowID,
				answers: [colID],
			})
		} else {
			// Take first entry (can't be more than 1 match by convention)
			let oldEntry = matchingEntries[0]
			// Remove this entry and create a new one
			answer = core.ensureRemoved(oldEntry, answer)
			let newEntry = {
				row: oldEntry.row,
				answers: oldEntry.answers.slice(),
			}

			if (isRadio) {
				// If max == 1, there can be only one answer, so just overwrite
				newEntry.answers = [colID]
			} else {
				// If max != 1: remove colID if already present, remove otherwise
				if (newEntry.answers.indexOf(colID) >= 0) {
					newEntry.answers = core.ensureRemoved(colID, newEntry.answers)
				} else {
					newEntry.answers = core.ensureAdded(colID, newEntry.answers)
				}
			}

			answer.push(newEntry)
		}

		callback(answer)
	}
}

const QuestionMatrixView = (p) => {
	let answer = p.answer || [];
	let isRadio = (p.question.spec.max === 1);
	let typeString = (isRadio ? 'radio' : 'checkbox');

	let thead = null;
	if (p.question.spec.style === core.MATRIX_STYLE_COMPACT) {
		thead = (
			<thead>
				<tr>
					<th style={{ width: '30%' }}>&nbsp;</th>
					{
						p.question.spec.cols.map((col) => (
							<th key={col.id} style={{
								textAlign: 'center',
								verticalAlign: 'middle',
								width: ((100-30)/p.question.spec.cols.length)+'%'
							}}>
								{ col.text }
							</th>
						))
					}
				</tr>
			</thead>
		);
	}

	return (
		<div className="QuestionMatrixView">
			<table className="table table-striped">
				{thead}
				<tbody>{ p.question.spec.rows.map((row) => (
					<tr key={row.id}>
						<td style={{ verticalAlign: 'middle' }}>{ row.text }</td>
						{ p.question.spec.cols.map((col) => (
							<td
								key={col.id}
								className="clickable col-cell"
								onClick={handleChange(answer, row.id, col.id, isRadio, p.onChange)}
							>
								<label className="col-label">
									<input
										type={typeString}
										checked={checked(answer, row.id, col.id)}
									/>
									<span>
										{' '}
										{p.question.spec.style === core.MATRIX_STYLE_VERBOSE ? col.text : null}
									</span>
								</label>
							</td>
						)) }
					</tr>
				))}</tbody>
			</table>
		</div>
	);
}

export default QuestionMatrixView;

/*
	{
		question: question,
		answer: answer,
		valid: valid,
		visible: visible,
	};
*/

/*
	table.table.table-striped(ng-class="{ redborder: highlightInvalid && !q.valid }")
		tr(ng-if="q.spec.style == STYLE_COMPACT")
			th(style='width: 30%;') &nbsp;
			th(ng-repeat="col in q.spec.cols" style='text-align: center; vertical-align: middle; width: {{ (100-30)/q.spec.cols.length }}%;') {{ col.text }}
		tr(ng-repeat="row in q.spec.rows" ng-init="row_idx = $index")
			td(style='vertical-align: middle;')
				| {{ row.text }}
			td(ng-repeat="col in q.spec.cols" style='text-align: center; vertical-align: middle;')
				div(ng-if="q.spec.max === 1")
					label
						input(type='radio' ng-model='q.answer[row.id]' ng-value='col.id' ng-change='updateModel()')
						| &nbsp;
						span(ng-if="q.spec.style == STYLE_VERBOSE") {{ col.text }}
				div(ng-if="q.spec.max !== 1")
					label
						input(type='checkbox' ng-model='q.answer[row.id][col.id]' ng-value='true' ng-change='updateModel()')
						| &nbsp;
						span(ng-if="q.spec.style == STYLE_VERBOSE") {{ col.text }}
*/
