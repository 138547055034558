import * as core from './core';


const initialState = {
	// server state, immutable by convention
	// - omitted, freeform
	// mutable state
	_loading: true,
	_error: null,
	_survey_hidden: false,
	_answers: {},
	_highlight_invalid: false,
	// cached state
	_child_questions: [],
	_visible_children: [],
	_current_flags: [],
	_visible_questions: [],
	_invalid_questions: [],
};

const extend = (what, how) => {
	return Object.assign({}, what, how);
}

const listPossibleChildren = (questions) => {
	let ret = [];
	if (!!questions) {
		for (let q of questions) {
			let pc = core.possibleChildren(q);
			for (let id of pc) {
				ret.push(id);
			}
		}
	}
	return ret;
}

const listVisibleChildren = (questions, answers) => {
	let ret = [];
	if (!!questions) {
		for (let q of questions) {
			let pc = core.visibleChildren(q, answers[q.id]);
			for (let id of pc) {
				ret.push(id);
			}
		}
	}
	return ret;
}

const getNewFlags = (questions, answers, currentFlags) => {
	currentFlags = currentFlags || [];
	let ret = currentFlags.slice();
	if (!!questions) {
		for (let q of questions) {
			ret = core.adjustFlags(q, answers[q.id], ret);
		}
	}
	return ret;
}

const listVisibleQuestions = (questions, currentFlags, childrenList, visibleChildrenList) => {
	let ret = [];
	if (!!questions) {
		for (let q of questions) {
			if (core.isVisible(q, currentFlags, childrenList, visibleChildrenList)) {
				ret.push(q.id);
			}
		}
	}
	return ret;
}

const listInvalidQuestions = (questions, answers, visibleIds) => {
	let ret = [];
	if (!!questions) {
		for (let q of questions) {
			if (visibleIds.indexOf(q.id) < 0) continue; // skip invisible
			if (!core.answerIsValid(q, answers[q.id])) {
				ret.push(q.id);
			}
		}
	}
	return ret;
}

const reducer = function(state = initialState, action) {
	let diff = null;
	let visibleChildrenList = null;
	let newInvalidList = null; // why all the `case` clauses share the same scope? that makes no sense
	let newFlags = null;
	let visibleQuestions = null;
	switch (action.type) {
		case 'SURVEY_START':
			return extend(state, {
				_survey_hidden: true,
			});
		case 'ANSWER_CHANGED':
			// write new answer
			diff = {};
			diff[action.id] = action.newAnswer;
			let newAnswers = extend(state._answers, diff);
			// update caches
			visibleChildrenList = listVisibleChildren(state.page_data.data.questions, newAnswers);
			newFlags = getNewFlags(state.page_data.data.questions, newAnswers, state.page_data.flags_current);
			visibleQuestions = listVisibleQuestions(state.page_data.data.questions, newFlags, state._child_questions, visibleChildrenList);
			newInvalidList = listInvalidQuestions(state.page_data.data.questions, newAnswers, visibleQuestions);
			// commit
			return extend(state, {
				_answers: newAnswers,

				_visible_children: visibleChildrenList,
				_current_flags: newFlags,
				_visible_questions: visibleQuestions,
				_invalid_questions: newInvalidList,
			});
		case 'SUBMIT_FAILED':
			return extend(state, {
				_highlight_invalid: true,
			});
		case 'LOADING_STARTED':
			return extend(state, {
				_loading: true,
				_error: null,
			});
		case 'LOADING_FAILED':
			return extend(state, {
				_loading: false,
				_error: action.error,
			});
		case 'LOADING_FINISHED':
			// restore answers if any
			newAnswers = {};
			if (!!action.newPageData.answers) {
				for (let obj of action.newPageData.answers) {
					newAnswers[obj.qid] = obj.answer;
				}
			}
			// update caches
			let childrenList = listPossibleChildren(action.newPageData.data.questions);
			visibleChildrenList = listVisibleChildren(action.newPageData.data.questions, newAnswers);
			newFlags = getNewFlags(action.newPageData.data.questions, newAnswers, action.newPageData.flags_current);
			visibleQuestions = listVisibleQuestions(action.newPageData.data.questions, newFlags, childrenList, visibleChildrenList);
			newInvalidList = listInvalidQuestions(action.newPageData.data.questions, newAnswers, visibleQuestions);
			// return new state
			diff = {
				page_data: action.newPageData,

				_loading: false,
				_answers: newAnswers,
				_highlight_invalid: false,

				_child_questions: childrenList,
				_visible_children: visibleChildrenList,
				_current_flags: newFlags,
				_visible_questions: visibleQuestions,
				_invalid_questions: newInvalidList,
			};
			if (!!action.newSurvey) {
				diff.survey = action.newSurvey;
			}
			if (!!action.newAttributes) {
				diff.attributes = action.newAttributes;
			}
			return extend(state, diff);
		default:
			return state;
	}
}

export default reducer;
