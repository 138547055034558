import React from 'react';
import epoch from './vendor/epochtalk-bbcode';

// This function accepts bbcode-formatted string as an input
// and returns React virtual DOM as an output - using whatever means to get it.
let go = (bbc) => {
	let res = epoch.process({ text: bbc });
	// TODO: check for res.error
	let html = res.html;
	return (
		<span dangerouslySetInnerHTML={{__html: html}}></span>
	);
}

export default go;
