import React, { Component } from "react"

import { range } from "./array"

import "./QuestionRatingView.css"


export default class QuestionRatingView extends Component {
	constructor(props) {
		super(props)

		this.state = {
			hoveredAnswer: {
				rowIndex: -1,
				itemIndex: -1,
			}
		}

		this.handleAnswer = this.handleAnswer.bind(this)
		this.handleAnswerHoverStart = this.handleAnswerHoverStart.bind(this)
		this.handleAnswerHoverEnd = this.handleAnswerHoverEnd.bind(this)
	}

	getAnswersMap() {
		return new Map((this.props.answer ?? []).map(e => [e.row, e.answer]))
	}

	syncAnswersWithRows() {
		const result = []
		const answersMap = this.getAnswersMap()

		this.props.question.spec.rows.forEach(e => {
			const answer = answersMap.get(e.id)
			result.push({row: e.id, answer: answer ?? 0})
		})

		return result
	}

	handleAnswer(rowIndex, rowID, value) {
		const answer = this.syncAnswersWithRows()
		answer[rowIndex] = {row: rowID, answer: value}

		this.props.onChange(answer)
	}

	handleAnswerHoverStart(rowIndex, itemIndex) {
		this.setState({hoveredAnswer: {rowIndex, itemIndex}})
	}

	handleAnswerHoverEnd() {
		this.setState({hoveredAnswer: {rowIndex: -1, itemIndex: -1}})
	}

	render() {
		const rows = []
		const answersMap = this.getAnswersMap()
		const [hoveredRowIndex, hoveredItemIndex] = [this.state.hoveredAnswer.rowIndex, this.state.hoveredAnswer.itemIndex]

		this.props.question.spec.rows.forEach((row, rowIndex) => {
			const currentAnswer = answersMap.get(row.id)
			const pureCurrentAnswer = currentAnswer ?? 0
			let rowIsValid

			if (row.is_required) {
				rowIsValid = pureCurrentAnswer > 0 && pureCurrentAnswer <= row.max
			} else {
				rowIsValid = pureCurrentAnswer >=0 && pureCurrentAnswer <= row.max
			}

			rows.push(
				<tr key={row.id} className={`${!this.props.isValid && !rowIsValid ? 'red' : ''}`}>
					<td>{row.text}</td>
					<td>{range(1, row.max + 1).map((value, itemIndex) => {
						const checked = currentAnswer !== undefined && currentAnswer >= value ? 'checked' : ''
						const hovered = hoveredRowIndex === rowIndex && itemIndex <= hoveredItemIndex ? 'hovered' : ''

						return (
							<i
								key={`${row.id}-${itemIndex}`}
								className={`clickable fa fa-star-o ${checked} ${hovered}`}
								onClick={() => this.handleAnswer(rowIndex, row.id, value)}
								onMouseEnter={() => this.handleAnswerHoverStart(rowIndex, itemIndex)}
								onMouseLeave={this.handleAnswerHoverEnd}
							/>
						)
					})}</td>
				</tr>
			)
		})

		return (
			<div className="QuestionRatingView">
				<table className="table">
					<thead>
						<tr>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{rows}
					</tbody>
				</table>
			</div>
		)
	}
}
