/**
 * Validates email.
 *
 * @param {string} email - Email to validate.
 * @returns {boolean} - true if email is valid otherwise false.
 * */
export function validateEmail(email) {
	return !!(
		email
		.toLowerCase()
		.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		)
	)
}