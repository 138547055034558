export default class NumericCondition {
	static get EQ() {
		return 1
	}
	static get GT() {
		return 2
	}
	static get LT() {
		return 3
	}
	static get GTE() {
		return 4
	}
	static get LTE() {
		return 5
	}

	/**
	 * Compares to numbers by specific condition.
	 *
	 * @param {number} condition - Comparison condition.
	 * @param {number} a - First number to compare.
	 * @param {number} b - Second number to compare.
	 * */
	static compare(condition, a, b) {
		switch (condition) {
			case (NumericCondition.EQ):
				return a === b
			case (NumericCondition.GT):
				return a < b
			case (NumericCondition.LT):
				return a > b
			case (NumericCondition.GTE):
				return a <= b
			case (NumericCondition.LTE):
				return a >= b
			default:
				throw new Error(`Unexpected condition ${condition}`)
		}
	}
}
