import React from 'react';


const NavigationView = (p) => (
	<div>
		<button style={{ display: (p.canRewind?'':'none') }} className="btn btn-primary" onClick={p.onRewind}>
			<i className="fa fa-arrow-left"></i> Назад
		</button>
		{' '}
		<button style={{ display: (p.canForward?'':'none') }} className="btn btn-primary btn-lg" onClick={p.onForward}>
			Продолжить <i className="fa fa-arrow-right"></i>
		</button>
	</div>
)

export default NavigationView;

/*
	p
		button.btn.btn-primary(ng-show='page_data.can_rewind === true' ng-disabled='spinner' ng-click='goBack()')
			i.fa.fa-arrow-left
			| #{' '}
			| Назад
		| #{' '}
		//-button.btn.btn-primary.btn-lg(ng-disabled='!data.allValid')
		button.btn.btn-primary.btn-lg(ng-show='page_data.finished === false' ng-disabled='spinner' ng-click='goNext()')
			| Продолжить
			| #{' '}
			i.fa.fa-arrow-right
*/
