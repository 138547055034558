import React from 'react'
import Select from 'react-select'

import * as core from './core'
import QuestionContainer from './QuestionContainer'

import './QuestionChoiceView.css'


const toggleCheckbox = (answer, id, callback) => {
	return () => {
		if (answer.indexOf(id) >= 0) {
			callback(answer.filter((x) => x !== id));
		} else {
			let x = answer.slice();
			x.push(id);
			callback(x);
		}
	};
}

const setRadio = (id, callback) => {
	return () => {
		callback([ id ]);
	};
}

const setSelect = (callback) => {
	return (event) => {
		let val = event.target.value;
		if (val === "") {
			callback([]);
		} else {
			callback([ +val ]);
		}
	};
}

const makeRSCallback = (callback) => {
	return (event) => {
		// console.log(event)
		if (!event) {
			callback([])
			return
		}

		if (event.length === undefined) {
			event = [event]
		}

		let values = event.map((x) => x.value)
		// console.log(values)
		callback(values)
	}
}


const QuestionChoiceView = (p) => {
	let answer = p.answer || [];
	let isRadio = (p.question.spec.max === 1);
	let typeString = (isRadio ? 'radio' : 'checkbox');
	let lockFurther = (!isRadio && (p.question.spec.max > 0) && (answer.length >= p.question.spec.max));
	let isTypeahead = (p.question.spec.autocomp === true);

	// special case, some rules are relaxed for this type
	if (isTypeahead) {
		let options = []
		// options.push(
		// 	<option value={""} key={""}>&mdash;</option>
		// );
		for (let choice of p.question.spec.choices) {
			let text = core.subst(choice.text, p.attributes)
			options.push(
				// <option value={choice.id} key={choice.id}>{choice.text}</option>
				{ label: text, value: choice.id }
			)
		}

		if (isRadio) {
			return (
				<Select
					multi={false}
					options={options}
					matchProp="label"
					onChange={makeRSCallback(p.onChange)}
					value={answer[0] || null}
				/>
				// <select value={currentValue} onChange={setSelect(p.onChange)}>
				// 	{options}
				// </select>
			)
		} else {
			return (
				<Select
					multi={true}
					options={options}
					matchProp="label"
					onChange={makeRSCallback(p.onChange)}
					value={answer}
				/>
			)
		}
	}

	// okay, here goes the usual stuff

	let tmp = p.question.spec.choices.map((option) => {
		let visible = core.flagsSatisfy(p.currentFlags, option.flags_needed);
		if (!visible) return null;

		let checked = (answer.indexOf(option.id) >= 0);
		let child = null;
		if (!!option.child_question) {
			if (answer.indexOf(option.id) >= 0) {
				child = (
					<div className="shift-right">
						<QuestionContainer qid={option.child_question} />
					</div>
				);
			}
		}

		let subheader = null;
		if (!!option.subheader) {
			subheader = (
				<h6 className="group"><b>{option.subheader}</b></h6>
			);
		}

		return (
			<div key={option.id} className={(isRadio && checked) ? 'bluebg':''}>
				{/* TODO: visibility by flags */}
				{subheader}
				<label className="choice-label">
					<input
						type={typeString}
						checked={checked}
						onClick={isRadio ? setRadio(option.id, p.onChange) : toggleCheckbox(answer, option.id, p.onChange)}
						disabled={!checked && lockFurther}
					/>
					<span className="clickable">&nbsp;{ core.subst(option.text, p.attributes) }</span>
				</label>
				{child}
			</div>
		);
	});

	return (
		<div className="QuestionChoiceView">{tmp}</div>
	);
}

export default QuestionChoiceView;

/*
	div(ng-if="q.type == TYPE_CHOICE")
		div(ng-repeat="option in q.spec.choices" ng-show="checkFlags(option.flags_needed)" ng-init="prevHeader = q.spec.choices[$index-1].subheader")
			h6(ng-if="prevHeader != option.subheader")
				b {{ option.subheader }}
			div.radio(ng-if="q.spec.max === 1" style='width: 100%; font-size: 17px' ng-class='{ "bluebg": q.answer == $index }')
				label(style='width: 100%; padding-top: 3px; padding-bottom: 3px')
					input(type='radio' ng-model='q.answer' ng-value='$index' ng-change='updateModel()')
					| #{' '}
					| {{ option.text }}
			div.checkbox(ng-if="q.spec.max !== 1" style='width: 100%; font-size: 17px')
				label(style='width: 100%; padding-top: 3px; padding-bottom: 3px')
					input(type='checkbox' ng-model='q.answer[$index]' ng-value='true' ng-change='updateModel()' ng-disabled='(q.answer[$index] !== true) && (q._lockFurther === true)')
					| #{' '}
					| {{ option.text }}
			//- if child_question is present
			div(ng-show='(option.child_question != null) && (option.child_question > 0)' style="padding-left: 30px")
				//- recurse using isolated scope
				div(ng-repeat='x in [1]' ng-init='q = qindex[option.child_question]')
					div(ng-include='"mixin_question.html"')
*/
