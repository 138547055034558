import { connect } from 'react-redux'
import ErrorView from './ErrorView'

const mapStateToProps = (state) => {
	return {
		error: state._error,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
	};
}

const ErrorContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(ErrorView)

export default ErrorContainer
