import { connect } from 'react-redux';
import DebugView from './DebugView';

const mapStateToProps = (state, ownProps) => {
	if (!ownProps.enabled) return {
		enabled: false,
	};

	return {
		state: state,
		enabled: true,
	};
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
	};
}

const DebugContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(DebugView)

export default DebugContainer
