/**
 * Returns a range as an array of numbers.
 *
 * @param {number} start - Start value of range.
 * @param {number|undefined} end - End value of range.
 * @param {number|undefined} step - The delta between the range values.
 * @returns {number[]} - Array of range numbers.
 * */
export function range(start, end = undefined, step = undefined) {
	if (step === 0) throw new Error("A step cannot be zero")
	if (step === undefined) step = 1
	if (end === undefined) [start, end] = [0, start]

	let allowStep
	let result = []

	if (start < end) {
		if (step < 0) return []
		allowStep = (a) => a < end
	} else {
		if (step > 0) return []
		allowStep = (a) => a > end
	}

	for (let i = start; allowStep(i); i += step) {
		result.push(i)
	}

	return result
}
