let prefix = '';
if (process.env.NODE_ENV !== 'production') {
	//prefix = 'http://localhost:81/api';
	prefix = '/api';
} else {
	//prefix = 'https://api.survey.comearth.ru/api';
	prefix = '/api';
}

export const API_PREFIX = prefix;

export const DEBUG_STATE = false;
export const DEBUG_LOG_DIFFS = false;
