import React, { Component } from 'react'

import { range } from "./array"
import * as core from "./core"

import "./QuestionScoreView.css"


export default class QuestionScoreView extends Component {
	constructor(props) {
		super(props)

		this.handleNPSAnswer = this.handleNPSAnswer.bind(this)
		this.handleSliderAnswer = this.handleSliderAnswer.bind(this)
	}

	handleNPSAnswer(event) {
		this.props.onChange && this.props.onChange(+event.target.value)
	}

	handleSliderAnswer(event) {
		this.props.onChange && this.props.onChange(+event.target.value)
	}

	render() {
		let answer = this.props.answer ?? []

		let body = null
		let {style, min, max} = this.props.question.spec

		switch (style) {
			case (core.SCORE_STYLE_NPS):
				let buttons = range(min, max + 1).map(e => {
					return (
						<label className="clickable" key={e}>
							<span>{e}</span>
							<input
								className="clickable"
								name="nps"
								type="radio"
								onChange={this.handleNPSAnswer}
								value={e}
							/>
						</label>
					)
				})

				body = (
					<fieldset className="nps-question">
						{buttons}
					</fieldset>
				)
				break
			case (core.SCORE_STYLE_SLIDER):
				body = (
					<input
						type="range"
						min={min}
						max={max}
						onChange={this.handleSliderAnswer}
						value={this.props.answer ?? 0}
					/>
				)
				break
			default:
				throw new Error(`Unexpected question style: ${style}`)
		}

		return (
			<div className="QuestionScoreView">
				{body}
			</div>
		)
	}
}
