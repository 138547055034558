/**
 * Validates ISO 8601 date string.
 *
 * @param {string} value - Date string to validate.
 * @returns {boolean} - `true` - if date is valid otherwise `false`.
 * */
export function validateISODate(value) {
	const dateParts = value.split('-')

	if (dateParts.length !== 3) return false
	const [year, month, day] = dateParts.map(Number)
	const date = new Date(year, month - 1, day)
	return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year
}
