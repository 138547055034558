import React from 'react';


const OutroView = (p) => (
	<div className="OutroView" style={{ display: p.finished?'':'none' }}>
		<h1>Спасибо!</h1>
		<p>Опрос завершён. Спасибо за участие!</p>
	</div>
)

export default OutroView;
