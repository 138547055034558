import { connect } from 'react-redux'
import * as actions from './actions'
import IntroView from './IntroView'

const mapStateToProps = (state) => {
	let attributes = {};
	if (!!state.page_data) {
		attributes = state.attributes;
	}

	return {
		survey: state.survey,
		hidden: state._survey_hidden,
		attributes: attributes,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		onClick: () => {
			dispatch(actions.startSurvey());
		},
	};
}

const IntroContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(IntroView)

export default IntroContainer
