import React from 'react';

import bbcode from './bbcode';
import * as core from './core';


const IntroView = (p) => {
	if (!p.survey) return null;
	if (p.hidden) return null;

	return (
		<div className="row">
			<div className="col-md-12">
				<div className="IntroView" style={{ marginBottom: '60px' }}>
					<div className="well well-lg">
						<h1>{ p.survey.title }</h1>
						<p>{ bbcode(core.subst(p.survey.intro, p.attributes)) }</p>
						<p>
							<button className="btn btn-primary" onClick={p.onClick}>Приступить к опросу</button>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default IntroView;
