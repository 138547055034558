import React, { Component } from 'react';
import './App.css';
import logo from './comearth-logo.png';
import * as config from './config';
import DebugContainer from './DebugContainer';
import SpinnerContainer from './SpinnerContainer';
import IntroContainer from './IntroContainer';
import PageContainer from './PageContainer';
import NavigationContainer from './NavigationContainer';
import OutroContainer from './OutroContainer';
import ErrorContainer from './ErrorContainer';

class App extends Component {
	render() {
		return (
			<div className="App container">
				<div className="row small-offset-top">
					<div className="col-md-2">
						<img src={logo} style={{ height: '100px' }} alt="Comearth logo" />
					</div>
					<div className="col-md-10">
						<DebugContainer enabled={config.DEBUG_STATE} />
						<SpinnerContainer />
						<ErrorContainer />
						<IntroContainer />
						<OutroContainer />
						<PageContainer />
						<NavigationContainer />
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<hr />
						<p className="text-muted">Опрос проводится на платформе компании &laquo;<a href="http://www.comearth-russia.com/">Комэрф</a>&raquo;.</p>
					</div>
				</div>
			</div>
		);
	}
}

export default App;
