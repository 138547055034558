import * as actions from './actions';
import * as config from './config';


const handleResponse = (response) => {
	// TODO: handle errors in sane way
	// Some ideas:
	// - error processing from legacy frontend;
	// - immediate throws (e.g. incorrect URL);
	// - 404;
	// - 403 or so from backend with body;
	// - 500 and so on.
	if (response.ok) {
		return response.json();
	} else {
		throw response;
	}
}

export const startSurvey = (payload) => {
	return (dispatch, getState) => {
		dispatch(actions.loadingStarted());

		let url = `${config.API_PREFIX}/pass/start/${payload.slug}?ephemeral=${payload.ephemeral}&forceRepass=${payload.forceRepass}&guid=${payload.guid}`;
		fetch(url, {
			method: 'POST',
			credentials: 'include',
		}).then((response) => {
			return handleResponse(response);
		}).then((response) => {
			dispatch(actions.loadingFinished(response.survey, response.attributes, response.page_data));
		}).catch((response) => {
			console.error(response);
			dispatch(actions.loadingFailed('Ошибка при отправке запроса'));

			response.json().then((body) => {
				dispatch(actions.loadingFailed(body.error || 'Ошибка при отправке запроса'));
			}).catch((err) => {
				// do nothing
			})
		});
	}
}

export const goForward = () => {
	return (dispatch, getState) => {
		dispatch(actions.loadingStarted());

		let state = getState();
		// {"page":94,"answers":[{"qid":848,"answer":[989]}]}
		let answers = [];
		for (let key in state._answers) { if (state._answers.hasOwnProperty(key)) {
			answers.push({ qid: +key, answer: state._answers[key] });
		}}
		let payload = {
			page: state.page_data.data.page.id,
			answers,
		}

		let url = `${config.API_PREFIX}/pass/fwd`;
		fetch(url, {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(payload),
		}).then((response) => {
			return handleResponse(response);
		}).then((response) => {
			dispatch(actions.loadingFinished(null, null, response));
		}).then(() => {
			let $ = window.$;
			$('html, body').animate({scrollTop: 0}, 'fast');
		}).catch((response) => {
			console.error(response);
			dispatch(actions.loadingFailed('Ошибка при отправке запроса'));

			response.json().then((body) => {
				dispatch(actions.loadingFailed(body.error || 'Ошибка при отправке запроса'));
			}).catch((err) => {
				// do nothing
			})
		});
	}
}

export const tryForward = () => {
	return (dispatch, getState) => {
		let state = getState();
		if (state._invalid_questions.length === 0) {
			dispatch(goForward());
		} else {
			// animate
			let selector = '#question'+state._invalid_questions[0];
			let $ = window.$;
			$('html, body').animate({scrollTop: $(selector).offset().top}, 'fast');
			// dispatch
			dispatch(actions.submitFailed());
		}
	}
}

export const goRewind = () => {
	return (dispatch, getState) => {
		dispatch(actions.loadingStarted());

		let state = getState();
		let payload = {
			page: state.page_data.data.page.id,
		}

		let url = `${config.API_PREFIX}/pass/back`;
		fetch(url, {
			method: 'POST',
			credentials: 'include',
			body: JSON.stringify(payload),
		}).then((response) => {
			return handleResponse(response);
		}).then((response) => {
			dispatch(actions.loadingFinished(null, null, response));
		}).then(() => {
			let $ = window.$;
			$('html, body').animate({scrollTop: 0}, 'fast');
		}).catch((response) => {
			console.error(response);
			dispatch(actions.loadingFailed('Ошибка при отправке запроса'));

			response.json().then((body) => {
				dispatch(actions.loadingFailed(body.error || 'Ошибка при отправке запроса'));
			}).catch((err) => {
				// do nothing
			})
		});
	}
}
