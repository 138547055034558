import React from 'react';
import './QuestionView.css';
import * as core from './core';
import QuestionTextView from './QuestionTextView';
import QuestionChoiceView from './QuestionChoiceView';
import QuestionMatrixView from './QuestionMatrixView';
import QuestionScoreView from "./QuestionScoreView";
import QuestionRatingView from "./QuestionRatingView";
import bbcode from './bbcode';


const QuestionView = (p) => {
	if (!p.visible) return null;

	let body = null;
	switch(p.question.type) {
		case core.TYPE_TEXT:
			body = (
				<QuestionTextView
					question={p.question}
					attributes={p.attributes}
					answer={p.answer}
					onChange={p.onChange}
					currentFlags={p.currentFlags}
				/>
			);
			break;
		case core.TYPE_CHOICE:
			body = (
				<QuestionChoiceView
					question={p.question}
					attributes={p.attributes}
					answer={p.answer}
					onChange={p.onChange}
					currentFlags={p.currentFlags}
				/>
			);
			break;
		case core.TYPE_MATRIX:
			body = (
				<QuestionMatrixView
					question={p.question}
					attributes={p.attributes}
					answer={p.answer}
					onChange={p.onChange}
					currentFlags={p.currentFlags}
				/>
			);
			break;
		case core.TYPE_SCORE:
			body = (
				<QuestionScoreView
					question={p.question}
					attributes={p.attributes}
					answer={p.answer}
					onChange={p.onChange}
					currentFlags={p.currentFlags}
				/>
			)
			break;
		case core.TYPE_RATING:
			body = (
				<QuestionRatingView
					question={p.question}
					attributes={p.attributes}
					answer={p.answer}
					onChange={p.onChange}
					currentFlags={p.currentFlags}
					isValid={p.valid}
				/>
			);
			break;
		default:
			body = (
				<div className="alert alert-warning">Тип вопроса {'{'}{p.question.type}{'}'} не поддерживается.</div>
			);
			break;
	}

	return (
		<div className="QuestionView">
			<div className={`vexpand ${!p.valid && p.question.type !== core.TYPE_RATING ? 'red' : ''}`}>
				<h3 id={'question'+p.question.id}>
					{bbcode(core.subst(p.question.text_primary, p.attributes))}
				</h3>
				{p.question.text_secondary ? <p className="text-muted">{bbcode(core.subst(p.question.text_secondary, p.attributes))}</p> : null}
				<div className="question__body">{body}</div>
			</div>
		</div>
	)
}

export default QuestionView;
