import { connect } from 'react-redux'
import OutroView from './OutroView'

const mapStateToProps = (state) => {
	let finished = false;
	if (!!state.page_data) finished = state.page_data.finished;
	return {
		finished: finished,
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
	};
}

const OutroContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(OutroView)

export default OutroContainer
