import React from 'react';


const ErrorView = (p) => {
	if (!p.error) return null;

	return (
		<div className="alert alert-danger" role="alert">
			<b>Ошибка:</b>{' '}{p.error.toString()}
		</div>
	);
}

export default ErrorView;
